import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  hashedId: string;
  name: string;
  profileLink: string;
  profilePicture: string;
}

const initialState: StateProps = {
  hashedId: '',
  name: '',
  profileLink: '',
  profilePicture: '',
};

const designerAttributionSlice = createSlice({
  name: 'designerAttribution',
  initialState,
  reducers: {
    updateDesignerAttribution: (state, action: PayloadAction<StateProps>) => {
        state.hashedId = action.payload.hashedId;
        state.name = action.payload.name;
      state.profileLink = action.payload.profileLink;
      state.profilePicture = action.payload.profilePicture;
    },
  },
});

export const {updateDesignerAttribution} = designerAttributionSlice.actions;
export const designerAttributionReducer = designerAttributionSlice.reducer;
