import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  onBoardingStatus: EditorMobileOnboardingStatus;
  hasOnboardingCompleted?: boolean;
}

export enum EditorMobileOnboardingStatus {
  NONE = '',
  BOTTOM_BAR_SCROLL = 'BOTTOM_BAR_SCROLL',
  ADD_ITEM = 'ADD_ITEM',
  CANVAS_ZOOM_PINCH = 'CANVAS_ZOOM_PINCH',
}

const initialState: StateProps = {
  onBoardingStatus: EditorMobileOnboardingStatus.NONE,
  hasOnboardingCompleted: false,
};

const editorMobileOnboardingSlice = createSlice({
  name: 'editorMobileOnboarding',
  initialState,
  reducers: {
    startOnboarding(state) {
      state.onBoardingStatus = EditorMobileOnboardingStatus.ADD_ITEM;
    },
    endOnboarding(state) {
      state.hasOnboardingCompleted = true;
      state.onBoardingStatus = EditorMobileOnboardingStatus.NONE;
    },
    toggleOnboardingState(state) {
      state.onBoardingStatus = getNewOnboardingState(state);
    },
  },
});

const getNewOnboardingState = (state: StateProps): EditorMobileOnboardingStatus => {
  let newState = EditorMobileOnboardingStatus.NONE;

  switch (state.onBoardingStatus) {
    case EditorMobileOnboardingStatus.BOTTOM_BAR_SCROLL:
      newState = EditorMobileOnboardingStatus.CANVAS_ZOOM_PINCH;
      break;
    case EditorMobileOnboardingStatus.ADD_ITEM:
      newState = EditorMobileOnboardingStatus.BOTTOM_BAR_SCROLL;
      break;
    case EditorMobileOnboardingStatus.CANVAS_ZOOM_PINCH:
    case EditorMobileOnboardingStatus.NONE:
    default:
      newState = EditorMobileOnboardingStatus.NONE;
      break;
  }

  return newState;
};

export const {startOnboarding, endOnboarding, toggleOnboardingState} = editorMobileOnboardingSlice.actions;
export const editorMobileOnboardingReducer = editorMobileOnboardingSlice.reducer;
