import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import {isLocal} from '@Libraries/environment-library';

export const ARE_AI_TRANSCRIPTS_ENABLED = isLocal();
export const PIXEL_TARGET_FIND_TOLERANCE = 10;
export const MOBILE_PIXEL_TARGET_FIND_TOLERANCE = 50;

export type GeneratedFromType = 'video' | 'audio';

export interface TranscriptItemObject extends BaseItemObject {
  subtitlesHashmap: Record<string, SubtitleObject>;
  verticalAlign: TextVerticalAlignType;
  generatedFrom?: TranscriptGeneratedFrom;
}

export interface TranscriptGeneratedFrom {
  type: 'video' | 'audio';
  hashFilename: string;
}
