export const DEFAULT_MESSAGE_TIMOUT_DURATION = 8000;

export enum GROWL_TYPE {
  SUCCESS = 'success',
  DANGER = 'danger',
  PREMIUM = 'premium',
  SECONDARY = 'secondary',
  NEUTRAL = 'neutral',
}

export interface ShowMessageGrowlProps {
  key?: string;
  text: string;
  interval?: number;
  type?: GROWL_TYPE;
  showCloseIcon?: boolean;
  icon?: string;
  dangerouslySetInnerHTML?: boolean;
}
