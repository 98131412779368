import {MODALS_IDS} from '@Components/modal-container';

export const POSTER_PUSH_MODAL_IDS: Record<string, boolean> = {
  [MODALS_IDS.POSTER_EDITOR_INTRO_ANIMATION_MODAL]: true,
  [MODALS_IDS.EDIT_AUDIO_ITEM_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TITLE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_GRID_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_BLEED_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FOLDS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_POSITION_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_OPACITY_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_FONT_SIZE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_LETTER_SPACING_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_LINE_HEIGHT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_LIST_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_SPELLCHECK_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_HYPERLINK_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_ALIGNMENT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_SHADOW_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_FLIP_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_STRETCH_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_ADJUSTMENTS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_LAYOUT_FILL_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TEXT_FILL_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_FILTERS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_BACKGROUND_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_EFFECTS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TEXT_OUTLINE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_QR_CONTENT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_QR_CONTENT_COLOR_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_QR_CONTENT_BACKGROUND_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_VECTOR_BORDER_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_VECTOR_FILL_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_EFFECT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_FONT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_SIZE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_COLOR_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_DRAWING_COLOR_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_NUMBER_OF_TABS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TABS_TEXT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_LAYOUT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_DATE_STYLES_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_2_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_BORDER_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_SPACING_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_MENU_ITEM_ICON_STYLES_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_SLIDESHOW_APPLY_PROPERTIES_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_SLIDESHOW_SLIDES_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TEXT_ITEM_FONT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_FONT_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_SLIDESHOW_TRANSITION_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_SHAPE_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TAB_ITEM_SEPARATOR_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_COLORS_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_TAB_ITEM_SEPARATOR_MODAL]: true,
  [MODALS_IDS.POSTER_EDITOR_EDIT_TEXT_MODAL]: true,
};
